<template>
	<div class="page-event-question ml-12 mt-6">
			<!-- Main Table -->
		<vs-table
			class="list-question"
			ref="table"
			search
			sst
			:data="questions"
			:max-items="limit"
			:total="totalQuestions"
			@search="onSearchList"
			@sort="onSort"
			@change-page="changePage">
			<div
				slot="header"
				class="flex flex-wrap-reverse items-center flex-grow justify-between">
				<div class="flex flex-wrap-reverse items-center data-list-btn-container">
							<!-- ADD NEW -->
							<div>
								<router-link class="text-blue" :to="`/events/${eventSlug}/interactive/questions/moderated`"> {{ $t('View Moderated Question')}} </router-link>
							</div>
					</div>
				<!-- ITEMS PER PAGE -->
				<vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 mt-5 items-per-page-handler">
					<div
						class="px-4 py-25 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
						<span class="mr-2">
							{{ page * limit - (limit - 1) }} - {{ questions.length > 0 ? page * limit : questions.length }} of {{ totalQuestions }}
						</span>
						<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
					</div>
					<!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
					<vs-dropdown-menu>
						<vs-dropdown-item @click="changeLimit(10)"><span>10</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(25)"><span>25</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(50)"><span>50</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(100)"><span>100</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(200)"><span>200</span></vs-dropdown-item>
					</vs-dropdown-menu>
				</vs-dropdown>
			</div>
			<template slot-scope="{data}">
				<div class="vx-col w-full sm:w-1/1 md:w-1/1 lg:w-1/1 mb-2" :data="question" v-for="(question, indexquestion) in data" :key="indexquestion">
					<vx-card v-if="!question.is_moderated">
						<div class="flex">
							<div class="mr-2 text-lg text-bold pt-0 mt-0">{{question.name}}</div>
							<div class="mr-2 pt-2px">-</div>
							<div class="pt-2px">{{ dateFormat(dataStart) }}</div>
						</div>
						<vs-row vs-type="flex" vs-justify="space-between" class="w-1/3">
							<vs-row vs-type="flex" vs-justify="flex-end" class="wrapper-btn-question">
								<vs-button @click="deleteData(question)" class="question-btn small icon-card-actions" color="#EBEBEB" icon-pack="feather" radius icon="icon-x"></vs-button>
								<vs-button v-if="!question.is_moderated" @click="sendToModerated(question.id)" class="question-btn ml-2 small icon-card-actions" color="#EBEBEB" icon-pack="feather" radius icon="icon-arrow-up"></vs-button>
								<vs-button v-if="question.is_moderated" @click="unsendToModerated(question.id)" class="question-btn ml-2 small icon-card-actions" color="#EBEBEB" icon-pack="feather" radius icon="icon-arrow-down"></vs-button>
							</vs-row>
						</vs-row>
						<p class="mb-1 mt-4"> {{question.content}} </p>
					</vx-card>
				</div>
			</template>
		</vs-table>
		<!-- Pagination -->
		<vs-pagination
			class="mt-3"
			:total="totalPages"
			:max-items="limit"
			:size-array="totalQuestions"
			v-model="page">
		</vs-pagination>
		</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  dateFormat,
  delay,
  getAxiosErrorMessage, duplicateVar,
} from '@/lib/helper';
import questionsApi from '@/api/question';

export default {
  name: 'EventQuestion',
  components: {
  },
  data() {
    return {
      event: null,
      questions: [],
      totalQuestions: 0,
      isFetching: false,
      isAll: false,
      page: 1,
      keyword: '',
      limit: 10,
      orderBy: 'created_at',
      sortBy: 'desc',
      totalPages: 0,
      questionId: '',
      selected: [],
      dataModearated: false,
      selectedItem: null,
    };
  },
  sockets: {
    connect() {
      this.initSocket();
    },
    question_create(payload) {
      this.questions.unshift(payload);
    },
    question_update(payload) {
      const index = this.questions.findIndex(curr => curr.id === payload.id);
      Object.assign(this.questions[index], payload);
    },
    question_delete(payload) {
      const index = this.questions.findIndex(curr => curr.id === payload);
      if (index !== -1) {
        this.questions.splice(index, 1);
      }
    },
  },
  methods: {
    initSocket() {
      const payload = {
        room_id: this.room,
      };
      // Join Room
      this.$socket.client.emit('join', payload);
    },
    leaveSocketRoom() {
      this.$socket.client.emit('leave', this.room);
    },
    fetch(reset) {
      this.$vs.loading();
      this.isFetching = true;
      if (reset) {
        this.page = 1;
        this.questions = [];
      }
      const keyword = this.keyword;
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
        event_slug: this.eventSlug,
      };
      if (keyword !== '') {
        params.keyword = keyword;
      }
      const callback = (response) => {
        const questions = response.data;
        this.questions = questions;
        const lastPage = response.lastPage;
        this.isAll = questions.length === 0 || questions.length < this.limit;
        // this.page++;
        if (lastPage) {
          this.totalPages = lastPage;
        }
        this.totalQuestions = response.total;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      questionsApi.list(params, callback, errorCallback);
    },
    sendToModerated(question) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Question',
        text: this.$t('Are you sure want to moderated this Question?'),
        accept: this.acceptModerated,
      });
      this.questionId = question;
    },
    acceptModerated() {
      const callback = () => {
        this.$vs.notify({
          color: 'success',
          title: 'Options',
          text: this.$t('Moderated Options was successfully'),
        });
        this.fetch();
      };
      const errorCallback = () => {
        this.$vs.notify({
          color: 'error',
          title: 'Event',
          text: this.$t('Published Options was failed'),
        });
      };
      const params = {
        is_moderated: 1,
      };
      questionsApi.update(this.questionId, params, callback, errorCallback);
    },

    unsendToModerated(question) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Question',
        text: this.$t('Are you sure want to unmoderated this Question?'),
        accept: this.unacceptModerated,
      });
      this.questionId = question;
    },
    unacceptModerated() {
      const callback = () => {
        this.$vs.notify({
          color: 'success',
          title: 'Options',
          text: this.$t('Unmoderated Options was successfully'),
        });
        this.fetch();
      };
      const errorCallback = () => {
        this.$vs.notify({
          color: 'error',
          title: 'Event',
          text: this.$t('Unmoderated Options was failed'),
        });
      };
      const params = {
        is_moderated: 0,
      };
      questionsApi.update(this.questionId, params, callback, errorCallback);
    },
    deleteData(item) {
      this.isDetailsVisible = false;
      this.selectedItem = duplicateVar(item);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: this.$t('Are you sure want to delete this Question?'),
        accept: this.deleteRecord,
        acceptText: this.$t('Delete'),
      });
    },
    deleteRecord() {
      this.$vs.loading();
      const item = this.selectedItem;
      const title = this.$t('Question');
      const callback = (response) => {
        this.remove(item.id);
        const message = response.message;
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      questionsApi.delete(item.id, callback, errorCallback);
    },
    remove(itemId) {
      const index = this.questions.findIndex(({ id }) => id === itemId);
      if (index !== -1) {
        this.questions.splice(index, 1);
      }
    },
    onSearchList(keyword) {
      delay(() => {
        this.keyword = keyword ? keyword.trim() : '';
        this.fetch(true);
      }, 1000);
    },
    onSort(sortKey, sortType) {
      this.orderBy = sortKey;
      this.sortBy = sortType;
      if (this.orderBy && this.sortBy) this.fetch(true);
    },
    changeLimit(value) {
      this.limit = value;
      this.fetch(true);
    },
    changePage(page) {
      this.page = page;
    },
    dateFormat(date) {
      return dateFormat(date, 'DD. MM. YYYY HH:mm');
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    eventSlug() {
      return this.$route.params.eventSlug;
    },
    room() {
      const room = `event-${this.eventSlug}`;
      return room;
    },
  },
  watch: {
    page() {
      this.fetch();
    },
    eventSlug() {
      if (this.eventSlug) this.initSocket();
    },
  },
  created() {
    this.fetch();
    this.initSocket();
  },
  beforeDestroy() {
    this.leaveSocketRoom();
  },
  destroyed() {
  },
};
</script>
<style>
.question-btn.vs-button{
    border-radius: 1.5rem!important;
}
.wrapper-btn-question{
	position: absolute;
	top: 0;
	right: 0;
	margin: 1em
}
.icon-x:before {
    color: black!important;
}
.icon-arrow-up:before {
    color: black!important;
}
.list-question.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    background-color: white;
}
.icon-arrow-down:before {
        color: black!important;
}
</style>
